import type { PropsWithChildren } from 'react'

export enum Theme {
  button = 'button',
  default = 'default',
  unstyled = 'unstyled',
}

export type Props = PropsWithChildren<{
  center?: boolean,
  isExternal?: boolean,
  onClick?: () => void,
  secondary?: boolean,
  showExternalIcon?: boolean,
  theme?: Theme,
  to: string,
  wide?: boolean,
  yellow?: boolean,
}>
